@import "src/styles/variables";

.Header {
  display: flex;
  justify-content: space-between;
  padding: 11px 15px;
  background: white;
  align-items: center;
  position: relative;
  z-index: 100;
}

.HeaderLogo {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  h3 {
    margin: 0;
    color: $primaryColor;
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
  }
}

.HeaderNavigationContainer {

}

.HeaderNavigationList {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  .ActiveLink {
    a {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  li {
    list-style: none;
    margin: 0 10px;
  }

  a, .ProfileLink {
    color: #757575;
    text-decoration: none;
    padding: 10px 15px;
    position: relative;
    font-size: 13px;

    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 3px;
      background: blue;
      right: calc(50% - 15px);
      bottom: 0;
      border-radius: 20px;
      transform: scaleX(0);
      transition: .1s;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.ProfileLink {
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 40px !important;
  cursor: pointer;
}


.Avatar {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  right: 0;
}

.HeaderBurgerWrap {
  display: none;
}

.HeaderBurgerButtonRoot {
  background-color: transparent !important;
  opacity: 1 !important;
}

.MobileProfileAvatarWrap {
  display: none;
}


@media only screen and (max-width: 1280px) {
  .MobileProfileAvatarWrap {
    display: flex;
  }
  .Header {
    border-radius: 0 0 0 30px;
    transition: .2s;

    &.OpenSidenavMobile {
      border-radius: 0;
    }
  }

  .HeaderLogo {
    justify-content: center;
    flex: 1;
  }

  .HeaderNavigationContainer {
    display: none;
  }

  .HeaderBurgerWrap {
    display: block;
  }
}
