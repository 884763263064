:global(#root) {
  display: block;
}

.page {
  position: relative;
  background-image: url("./images/sertificateBackground.png");
  background-size: 100% 100%;
  min-height: 100vh;
}

.innerContainer {
  padding: 80px;
}

.backgroundImage {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.logoWrapper {
  margin-top: 52px;
  text-align: center;
}

.title {
  margin-top: 16px;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-transform: uppercase;
  color: #4e2a8e;
}

.songNameContainer {
  margin-top: 43px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.songNameTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #0d1336;
}

.songName {
  margin-top: 18px;
  margin-bottom: 18px;
  font-size: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  color: #0d1336;
}

.dateWrapper {
  margin-top: 11px;
  text-align: center;
  padding-right: 15px;
}

.dateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.dateIconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ede8f1;
  border-radius: 50%;
}

.dateIcon {
  width: 12px;
}

.createdDate {
  margin-left: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #5f6d95;
}

.detailsContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detailsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
}

.detailsBlockTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #4e2a8e;
}

.fieldsWrapper {
  margin-top: 36px;
}

.fieldContainer {
  padding-bottom: 3px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 21px;
}

.fieldTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-transform: capitalize;
  color: #5f6d95;
  opacity: 0.4;
}

.fieldValue {
  display: block;
  margin-top: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  color: #3d4867;
  overflow-wrap: anywhere;
  &.simple {
    text-transform: none;
  }
}

.fieldContainerRight {
  padding-bottom: 3px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 21px;
}
