@mixin font-face($font-family, $url) {
  @font-face {
    font-family: '#{$font-family}';
    src: url('#{$url}.eot');
    src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
    url('#{$url}.woff') format('woff'),
    url('#{$url}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin titleFont {
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

@mixin whenSmall {
  @media (max-width: 1279.95px) {
    @content;
  }
}

@mixin whenWide {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin whenAbove ($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin whenAboveMobile {
  @media (min-width: 425px) {
    @content;
  }
}
