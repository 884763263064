@import "styles/fonts";
@import "styles/variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Proxima Nova Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
}

.full-screen-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000000;
  background: white;
  transition: .2s;

  &.hide {
    opacity: 0;
  }

  &.remove {
    display: none;
  }
}


.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.align-items-center {
  align-items: center;
}


.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.text-left {
  text-align: left !important;
}

.bold-text {
  font-weight: bold;
}

.m-0 {
  margin: 0 !important;
}

.flex-1 {
  flex: 1;
}

.w-50 {
  width: 50%;
}


.w-100 {
  width: 100%;
}


.registerFileIcon {
  padding: 8px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #DFFBF3;
  box-shadow: 20px 20px 50px rgba(189, 200, 221, 0.2);
  border-radius: 200px;
}


@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.90);
  }
  to {
    transform: scale(1);
  }
}


.PulseButtonWrap {
  color: #fff;
  display: flex;
  cursor: pointer;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  align-items: center;
}

.active-color {
  color: $activeColor !important;
}

.PulseButton {
  background: #7AE9CE;
  box-shadow: 10px 10px 40px rgba(101, 86, 252, 0.4);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &.primary-bg {
    background: #776BF8;

    &:after,
    &:before {
      background: #4A39FF;
    }
  }

  &:after,
  &:before {
    content: '';
    background: #7AE9CE;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
  }

  &:after {
    width: 33px;
    height: 33px;
    opacity: 0.1;
  }

  &:before {
    width: 45px;
    height: 45px;
    opacity: 0.07;
  }

  &:hover {
    animation-name: pulse;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-iteration-count: infinite;

    &:after {
      animation-delay: 0.5s;
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }

    &:before {
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
}


.loading-content-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: #F5F6FF;
}


.circular-icon-wrap {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 23px;

  &.red {
    background: rgba(255, 165, 165, 0.11);
  }

  &.green {
    background: rgba(122, 233, 206, 0.11);
  }
}


.cursor-default {
  cursor: default !important;
}

.auth0-lock-name {
  display: none;
}

.auth0-lock-content-body-wrapper {
  margin-top: 110px !important;
}


@media only screen and (max-width: 1024px) {
  #root {
    background: #F5F6FF;
  }
}


