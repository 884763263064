$OPEN_SIDENAV_translateX: 256px;

.MainLayout {
  //background: #F5F6FF;
  flex-direction: column;
  display: flex;
  flex: 1;
  min-height: 100vh;
}

.SidenavContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}


.Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #F5F6FF;
  border-radius: 90px 0 0 0;
  overflow: hidden;

  &.SindenavOff {
    border-radius: 0;
  }
}


.Raw {
  overflow: auto;
  padding: 72px 52px 60px 52px;
  min-height: 100%;
  position: relative;
}

.Content {
  max-width: 1024px;
  margin: auto;
}

@media only screen and (max-width: 1280px) {
  .Content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .Container {
    border-radius: 0;
  }


  .Raw {
    padding: 30px 20px;
  }
}
