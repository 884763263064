.main_header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 46px calc(100vw - 1920px + 397px) 46px calc(100vw - 1920px + 385px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_header.fixed {
  position: fixed;
  background: #0b122a;
  padding: 22px 40px;
  color: #fff;
  z-index: 8888;
  transform: translateY(-110%);
  -webkit-box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.logo {
  flex-shrink: 0;
  width: 100px;
  height: 30px;
}

.main_header.fixed.active {
  transform: translateY(0%);
  transition: transform .2s ease-in-out;
}

.main_header .main_nav li a {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #F2F2F2;
  opacity: 0.9;
}

.nav_holder_logo {
  display: none;
}

.nav_holder {
  margin: 0 0 0 45px;
}

.nav_footer_bar,
.nav_holder_logo {
  display: none;
}

.main_nav > a {
  display: none;
}

.main_nav ul {
  font-size: 0;
  line-height: 0;
}

.main_nav li {
  display: inline-block;
  vertical-align: top;
  margin: 0 28px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.main_header.fixed .main_nav li a {
  color: #fff;
}

.main_nav li a {
  display: block;
  padding: 10px 8px;
  position: relative;
}

.burger_menu {
  display: none;
}

.burger_menu {
  cursor: pointer;
  display: none;
  width: 36px;
  height: 22px;
  position: relative;
  z-index: 1000;
  margin: 0 0 0 20px;
}

.burger_menu span,
.burger_menu:before,
.burger_menu:after {
  content: '';
  position: absolute;
  right: 0;
  height: 4px;
  border-radius: 10px;
  background: #fff;
  transition: .2s all ease-in-out;
  z-index: 100;
  pointer-events: none;
}

.burger_menu span {
  width: 75%;
  top: 50%;
  transform: translateY(-50%);
}

.burger_menu:before {
  width: 100%;
  bottom: 0;
}

.burger_menu:after {
  width: 50%;
  top: 0;
}

.burger_menu.open span {
  transform: translate(-100%, -50%);
  opacity: 0;
  visibility: hidden;
}

.burger_menu.open:before {
  right: calc(50% - 10px);
  bottom: calc(50% - 2px);
  transform: rotate(45deg);
  width: 20px;
}

.burger_menu.open:after {
  right: calc(50% - 10px);
  top: calc(50% - 2px);
  bottom: auto;
  transform: rotate(-225deg);
  width: 20px;
}

.burger_menu .burger_menu_circle {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
  line-height: 0;
}

.burger_menu svg {
  width: 100%;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.burger_menu svg path {
  fill: none;
  stroke-width: 2px;
  stroke: rgba(255, 255, 255, .5);
  transition: all 0.6s ease-in-out;
  stroke-dashoffset: 157;
  stroke-dasharray: 157;
}

.burger_menu.open svg {
  transform: rotate(245deg);
}

.burger_menu.open svg path {
  transition: all 0.6s ease-in-out .2s;
  stroke-dashoffset: 0;
}

.wave_btn {
  position: relative;
  transition: all .2s ease-out;
}

.wave_btn.wave_btn_dark:before {
  border-color: #3F3F3F;
}

.wave_btn:hover {
  transform: scale(0.9);
}

.wave_btn:before {
  content: '';
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid #fff;
  transform: translate(-50%, -50%) scale(1);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  pointer-events: none;
  border-radius: 50%;
}

.wave_btn:hover:before {
  visibility: visible;
  transition: transform .4s ease-in-out, opacity .4s ease-in-out;
  transform: translate(-50%, -50%) scale(1.7);
  opacity: 0;
}

.nav_wrapper {
  display: flex;
  align-items: center;
}

.nav_btn {
  margin-left: 70px;

  button, a {
    display: inline-block;
    background: transparent;
    vertical-align: top;
    padding: 12px 25px;
    text-align: center;
    border: 1px solid #FF467D;
    border-radius: 50px;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FF467D;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: rgba(255, 70, 79, .1);
      transition: 0.1s ease-in;
    }
  }
}

@media screen and (max-width: 1600px) {
  .main_header {
    padding-left: 40px;
    padding-right: 35px;
  }
  .main_nav li {
    margin: 0 10px;
  }
  .nav_holder {
    margin: 0 0 0 20px;
  }
}

@media screen and (max-width: 1360px) {
  .nav_wrapper .nav_btn {
    margin: 0 0 0 20px;
  }
}

@media screen and (max-width: 1220px) {
  .main_header {
    padding: 30px 20px;
  }
  .main_header.fixed {
    padding: 22px 20px;
  }
  .logo {
    width: 100px;
    height: 41px;
  }
  .main_nav li {
    font-size: 14px;
    line-height: 16px;
  }
}

.HeaderNavigationList {
  .ActiveLink {
    a {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  li {
    list-style: none;
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    font-size: 13px;

    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 3px;
      background: blue;
      right: calc(50% - 15px);
      bottom: 0;
      border-radius: 20px;
      transform: scaleX(0);
      transition: .1s;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}


@media screen and (min-width: 961px) {
  .nav_holder {
    display: block !important;
  }
  .HeaderNavigationList {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .main_nav {
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      li a {
        font-size: 20px;

        &::after {
          width: 50px;
          left: 8px;
        }
      }
    }
  }
  .burger_menu {
    display: block;
  }
  .main_header.fixed {
    transform: translate(0)
  }
  .main_header {
    padding: 22px 20px;
    position: fixed;
    z-index: 999;
  }
  .main_header.fixed .main_nav li.ActiveLink a,
  .main_header .main_nav li.ActiveLink a {
    color: #fff;
  }
  .main_header.fixed .main_nav li a,
  .main_header .main_nav li a {
    color: rgba(255, 255, 255, .4);
  }
  .main_header.fixed .burger_menu span,
  .main_header.fixed .burger_menu:before,
  .main_header.fixed .burger_menu:after {
    background: #fff;
  }
  .main_header.fixed .burger_menu.open span,
  .main_header.fixed .burger_menu.open:before,
  .main_header.fixed .burger_menu.open:after {
    background: #fff;
  }
  .nav_wrapper > .nav_btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .nav_holder_logo {
    transform: translateY(-200%);
    display: block;
    position: absolute;
    left: 21px;
    top: 22px;
    width: 150px;
    height: 40px;
    transition: transform .2s ease-in-out;
  }
  .nav_holder.open .nav_holder_logo {
    transition: transform .2s ease-in-out .3s;
    transform: translateY(0);
  }
  .nav_holder_logo img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .nav_holder {
    display: none;
    position: fixed;
    padding: 100px 35px 70px;
    left: 0;
    top: 0;
    right: 0;
    /*bottom: 0;*/
    height: 100vh;
    background: #2E2D2D;
    margin: 0;
    z-index: 999;
  }
  .nav_holder.open .nav_footer_bar {
    transform: translateY(0%);
  }
  .main_nav > a {
    display: block;
  }
  .main_nav {
    height: 100%;
    overflow: auto;
  }
  .main_nav > a,
  .main_nav li {
    display: block;
    margin: 0 0 20px;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 550px) {
  .logo {
    width: 47px;
  }
  .logo a {
    background-size: 151px;
    background-position: left center;
  }
  .nav_wrapper > .nav_btn a {
    font-size: 12px;
    line-height: 15px;
  }
  .main_nav > a,
  .main_nav li {
    text-align: left;
  }
}
