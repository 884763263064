@import "./mixins";

@include font-face("Proxima Nova Black", "fonts/ProximaNova/ProximaNova-Black");
@include font-face(
  "Proxima Nova Regular",
  "fonts/ProximaNova/ProximaNova-Regular"
);
@include font-face("Proxima Nova Light", "fonts/ProximaNova/ProximaNova-Light");
@include font-face("Proxima Nova Thin", "fonts/ProximaNova/ProximaNova-ThinIt");
@include font-face("Proxima Nova Bold", "fonts/ProximaNova/ProximaNova-Bold");
@include font-face("Poppins Black", "fonts/Poppins/Poppins-Black");
@include font-face("Poppins Bold", "fonts/Poppins/Poppins-Bold");
@include font-face("Poppins Regular", "fonts/Poppins/Poppins-Regular");

@font-face {
  font-family: "Montserrat";
  src: url("fonts/Montserrat/MontserratRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
