$primaryColor: #2D3959;
$primaryTextColor: #5F6D95;
$activeColor: #7AE9CE;
$primaryWhiteColor: #F2F2F2;
$errorColor: #FFA5A5;


@function primaryTextColorOpacity($opacity) {
  @return rgba(95, 109, 149, $opacity);
}


@function activeColorOpacity($opacity) {
  @return rgba(122, 233, 206, $opacity);
}

@function errorColorOpacity($opacity) {
  @return rgba(255, 165, 165, $opacity);
}


