@import "src/styles/variables";

.Sidenav {
  background: white;
  padding: 65px 0 0 33px;
  max-width: 290px;
}

.SidenavSimpleUserInfo {
  display: block;
}

.SidenavAvatar {
  width: 63px;
  height: 63px;
  margin-bottom: 21px;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.SidenavUserName {
  padding-right: 40px;

  h4 {
    margin: 0;
    font-size: 36px;
    line-height: 44px;
    color: $primaryColor;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    font-weight: 600;
  }

  span {
    font-weight: 100;
    font-family: 'Proxima Nova Light', sans-serif;
  }

  .SidenavUserNameShortDesc {
    margin-bottom: 65px;
    font-family: 'Poppins Regular', sans-serif;
    color: #5F6D95;
    opacity: 0.8;
    font-style: italic;
    font-size: 13px;
  }
}


.SidenavNavigationList {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 15px 0;
      font-weight: 600;
      font-size: 14px;
      color: $primaryTextColor;
      opacity: 0.56;
      transition: .1s;
      position: relative;

      &:after {
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 3px;
        background: #776BF8;
        transition: .2s;
        transform: scaleY(0);
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;

        span {
          padding-left: 10px;
        }
      }

      &.SidenavNavigationListActiveItem {
        color: #776BF8;
        opacity: 1;

        &:after {
          transform: scaleY(1);

        }
      }
    }
  }
}

.SidenavOverlayForMobile {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .SidenavSimpleUserInfo {
    display: none;
  }

  .SidenavOverlayForMobile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    animation-name: show;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transition: 0.2s;

    &.SidenavOverlayForMobileVisible {
      animation-name: hide;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      transition: 0.2s;
    }
  }

  .Sidenav {
    position: absolute;
    padding: 30px 0 0 20px;
    left: 0;
    top: 0;
    transition: .2s;
    height: 100%;
    z-index: 100;
    transform: translateX(-300px);

    &.OpenSidenav {
      transform: translateX(0);
    }
  }

  .SidenavNavigationList {
    ul {
      li {
        padding: 15px 30px 15px 0;
      }
    }
  }
}


@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
